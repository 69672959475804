.medias_wrapper {
  display: flex;
  width: 63vw;
  flex-wrap: wrap;
  gap: 16px;
}
.media_header_wrapper {
  box-shadow: 0px 0px 16px rgba(49, 58, 75, 0.11);
  padding: 60px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.media_wrapper {
  width: 350px;
}
.media_info_pdf_wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
.media_info_pdf {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #151c26;
  text-align: justify;
  font-size: 20px;
  font-weight: 600;
  line-height: 31px; /* 155% */
  cursor: pointer;
}
.media_info_pdf img {
  width: 20px;
  height: 25px;
}
.media_pdf_price {
  color: #313a4b;
  font-size: 20px;
  font-weight: 600;
}
.media_img_wrapper .media_img_bg {
  width: 100%;
  height: 100%;
}
.media_info_title {
  color: #151c26;
  text-align: justify;
  font-size: 20px;
  font-weight: 600;
  line-height: 31px;
  margin: 0;
}
.media_info_desc {
  color: #151c26;
  text-align: justify;
  font-size: 18px;
  font-weight: 500;
  line-height: 31px;
  margin: 0;
}
.media_img_wrapper {
  position: relative;
}
.media_play_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
}
.media_play_btn img {
  width: 100%;
  height: 100%;
}
.media_video {
  padding: 10px;
  background: #fff;
}
.video_modal .signUp_content_header {
  background: #fff;
  margin-bottom: 10px;
}
.video_modal .signUp_content_header .close {
  left: 20px;
}
.media_pdf_wrapper {
  cursor: pointer;
}
@media screen and (max-width: 1679px) {
  .media_wrapper {
    width: 300px;
  }
  .media_info_title,
  .media_info_pdf,
  .media_pdf_price {
    font-size: 16px;
  }
  .media_info_desc {
    font-size: 14px;
    line-height: 25px;
  }
}
@media screen and (max-width: 1480px) {
  .media_wrapper {
    width: 270px;
  }
  .media_info_title,
  .media_info_pdf,
  .media_pdf_price {
    font-size: 14px;
  }
  .media_info_desc {
    font-size: 12px;
    line-height: 20px;
  }
}
@media screen and (max-width: 1330px) {
  .media_wrapper {
    width: 240px;
  }
  .media_info_title,
  .media_info_pdf,
  .media_pdf_price {
    font-size: 14px;
  }
  .media_info_desc {
    font-size: 12px;
    line-height: 20px;
  }
  .media_play_btn {
    width: 50px;
    height: 50px;
  }
}
@media screen and (max-width: 1190px) {
  .media_wrapper {
    width: 200px;
  }
  .media_info_title,
  .media_info_pdf,
  .media_pdf_price {
    font-size: 12px;
  }
  .media_info_desc {
    font-size: 10px;
    line-height: 15px;
  }
}
@media screen and (max-width: 1000px) {
  .media_wrapper {
    width: 170px;
  }
  .media_info_title,
  .media_info_pdf,
  .media_pdf_price {
    font-size: 10px;
  }
  .media_info_desc {
    font-size: 8px;
    line-height: 10px;
  }
}
@media screen and (max-width: 900px) {
  .medias_wrapper {
    width: 96vw;
  }
  .media_wrapper {
    width: 250px;
  }
  .media_info_title,
  .media_info_pdf,
  .media_pdf_price {
    font-size: 16px;
  }
  .media_info_desc {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 800px) {
  .media_wrapper {
    width: 100%;
  }
}
.media_pdf_modal_book_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  background: #fff;
  padding: 70px 40px;
}
.media_book_img {
  border-radius: 10px;
  background: rgba(234, 237, 245, 0.41);
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: 220px;
  height: 300px;
  padding: 20px;
}
.media_book_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.media_book_pdf_wrapper {
  display: flex;
  align-items: center;
  gap: 45px;
}
.media_book_desc_info {
  font-weight: 500;
  line-height: 120%;
}
.media_book_desc_wrapper {
  width: 50%;
}
.media_book_price span {
  color: #dd264e;
}
