.projectsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 63vw;
}
.slider,
.carousel {
  position: relative;
}
.carousel {
  overflow-x: hidden;
}
.slider {
  width: inherit;
  padding-left: 50px;
}
.innerCarousel {
  display: flex;
  gap: 25px;
}
.leftBtn,
.rightBtn {
  width: 62px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
  padding: 20px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  transition: 0.3s ease;
}
.leftBtn img,
.rightBtn img {
  width: 18px;
  height: 18px;
}
.leftBtn {
  left: 19px;
}

.rightBtn {
  right: -25px;
  transform: translateY(-50%) rotate(180deg);
}
.leftBtn:active {
  transform: translateY(-50%) scale(0.85);
}

.rightBtn:active {
  transform: translateY(-50%) scale(0.85) rotate(180deg);
}
@media screen and (max-width: 1600px) {
  .rightBtn,
  .leftBtn {
    width: 52px;
    height: 52px;
  }
}
@media screen and (max-width: 1280px) {
  .rightBtn,
  .leftBtn {
    width: 42px;
    height: 42px;
  }
  .rightBtn img,
  .leftBtn img {
    width: 13px;
    height: 13px;
  }
}
@media screen and (max-width: 975px) {
  .leftBtn,
  .rightBtn {
    display: none;
  }
  .slider .innerCarousel {
    gap: 16px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .slider,
  .projectsContainer {
    width: 100%;
    padding: 0;
  }
  .projectsContainer .btns {
    width: 70vw !important;
  }
}
