.modal_signIn {
  display: flex;
  border-radius: 9px;
  background: #fff;
  max-height: 90vh;
}
.forgetPassword,
.modal_signIn_leftSide {
  display: flex;
  align-items: center;
}
.forgetPassword {
  justify-content: end;
}
.modal_signIn_leftSide {
  width: 300px;
  border-radius: inherit;
  background-color: var(--text-color);
  flex-direction: column;
  justify-content: center;
  gap: 45px;
  padding: 20px 0;
}
.modal_signIn_rightSide {
  padding: 60px;
  width: 480px;
  position: relative;
}
.signIn_formFields {
  display: flex;
  flex-direction: column;
}
.forgetPassword button {
  color: var(--text-color-2);
  text-align: end;
  font-size: 14px;
  font-weight: 500;
  line-height: 221.429%;
  margin-bottom: 15px;
}
.signIn_form {
  font-size: 14px;
}
.signIn_formFields .form {
  border-radius: 46px;
  border: 1px solid var(--text-color-2);
  box-shadow: -20px 8px 20px 0px rgba(21, 29, 39, 0.06);
  color: var(--text-color);
  font-weight: 500;
  font-size: 14px;
}
.signIn_formFields .form::placeholder {
  color: var(--text-color-2);
  text-align: justify;
  font-size: 14px;
  font-weight: 500;
  line-height: 221.429%;
}
.modal_signIn_title {
  color: var(--text-color);
  font-size: 16px;
  font-weight: 700;
}
.signIn_another_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  color: var(--text-color-2);
}
.signIn_another_title .line {
  height: 1px;
  width: 80px;
  background-color: var(--text-color-2);
}
.signIn_another_icons,
.signIn_another_icons button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.signIn_another_icons {
  gap: 20px;
}
.signIn_another_icons button {
  border-radius: 46px;
  border: 1px solid var(--text-color-2);
  padding: 10px 30px;
  width: 100%;
}
.signIn_signUp {
  font-size: 16px;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 70px;
  left: 0;
}
.signIn_signUp,
.forgetPass_desc {
  color: var(--text-color-2);
  text-align: center;
  font-weight: 500;
  line-height: 132.143%;
}
.forgetPass_desc {
  text-align: left;
}
.signIn_another {
  margin-top: 20px;
  margin-bottom: 70px;
}
.error {
  font-size: 12px;
  color: var(--main-color);
  font-weight: 500;
  margin: 7px 0;
}
.checkEmail_icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-height: 685px) {
  .modal_signIn_rightSide {
    padding: 50px 70px;
  }
  .modal_signIn_leftSide img:first-child,
  .modal_signIn_leftSide img:last-child {
    width: 75px;
  }
  .modal_signIn_leftSide img:nth-child(2) {
    width: 200px;
  }
  .signIn_signUp {
    bottom: 25px;
  }
  .modal_signIn_title p {
    margin-top: 0;
  }
}
@media screen and (max-width: 790px) {
  .modal_signIn {
    width: 100vw;
  }
  .signIn_formFields .form {
    padding: 12px;
    padding-left: 23px;
  }
  .signIn_another_title {
    font-size: 16px;
  }
  .forgetPass_desc {
    font-size: 14px;
  }
  .modal_signIn_title_forgetPass {
    margin-bottom: 80px;
  }
  .signIn_another_privacy_forgetPass {
    margin-top: 36px;
  }
  .signIn_another_privacy_forgetPass p {
    text-align: initial;
  }
  .signIn_another_privacy_forgetPass p .mentioned_txt {
    display: block;
  }
}
@media screen and (max-width: 900px) {
  .modal_signIn_rightSide {
    width: 100%;
    padding: 23px;
  }
  .modal_signIn {
    height: 100%;
  }
}
