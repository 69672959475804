.revolvedTxt path {
  fill: transparent;
}
.revolvedTxt text {
  fill: #fff;
}
.revolvedTxt {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 6px;
}
