.aboutUs_dashedLine,
.aboutUs_privacy {
  width: 63vw;
}
.aboutUs_dashedLine {
  border: 1px dashed #000;
}
.aboutUs_privacy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.aboutUs_privacy a,
.aboutUs_privacy p {
  margin: 0;
  padding: 0;
  line-height: 155%;
  font-weight: 500;
  color: var(--mention-color);
  font-size: 14px !important;
}
.aboutUs-bigPattern-1,
.aboutUs-bigPattern-2,
.aboutUs-bigPattern-3 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.aboutUs-bigPattern-1,
.aboutUs-bigPattern-3 {
  width: 25vw;
}
.aboutUs-bigPattern-1 img,
.aboutUs-bigPattern-2 img,
.aboutUs-bigPattern-3 img {
  width: 100%;
  height: 100%;
}
.aboutUs-bigPattern-1 {
  top: 200px;
}
.aboutUs-bigPattern-2 {
  width: 35vw;
  top: 45%;
  transform: translate(-50%, -50%);
}
.aboutUs-bigPattern-3 {
  bottom: 300px;
}
.faq_header {
  color: #151c26;
  font-weight: 600;
  font-size: clamp(2rem, 0rem + 1.6667vw, 6rem);
}
.faq_collapse {
  width: 63vw;
  border-radius: 0;
  border: none;
  font-family: Montserrat;
}
.faq_collapse .ant-collapse-item {
  border-left: none;
  border-right: none;
  /* border-bottom: none; */
}
.faq_collapse .ant-collapse-item:nth-child(2),
.faq_collapse .ant-collapse-item:nth-child(1) {
  border-bottom: none;
}
.faq_collapse .ant-collapse-header {
  flex-direction: row-reverse;
  border-radius: 0 !important;
  background-color: transparent !important;
  font-weight: 600;
  padding-left: 0 !important;
  border: 1px solid rgba(49, 58, 75, 0.36) !important;
  border-left: none !important;
  border-right: none !important;
}
.faq_collapse .ant-collapse-header,
.faq_info {
  font-size: clamp(1.5rem, 0rem + 1.25vw, 4.5rem);
}
.faq_collapse .ant-collapse-content-box {
  padding-left: 0 !important;
}
.faq_info {
  color: var(--main-color);
  font-weight: 600;
  width: 63vw;
}
.faq_collapse .ant-collapse-expand-icon {
  position: absolute;
  left: 55%;
  top: 50%;
  transform: translateY(-50%);
}

.faq_qesutions {
  width: 63vw;
}
.faq_question h1 {
  font-size: 1.7999999999999998vw;
  margin: 20px 0;
}
.faq_q,
.faq_collapse .ant-collapse-header-text {
  font-size: 1.2vw;
  color: #151c26;
  font-weight: 600;
}
.ant-collapse-content .ant-collapse-content-active {
  border-radius: 0 !important;
}
.faq_a {
  font-size: 1vw;
  color: #313a4b;
  text-align: justify;
  font-weight: 500;
  line-height: 155%;
}
.faq_a_4 .faq_a span {
  color: #313a4b !important;
  font-weight: 500 !important;
}
.faq_panel p{
  margin: 0;
}
@media screen and (max-width: 975px) {
  .aboutUs_privacy {
    width: 100%;
  }
  .aboutUs_dashedLine {
    width: 81vw;
  }
  .aboutUs-bigPattern-1,
  .aboutUs-bigPattern-3 {
    width: 63vw;
  }
  .aboutUs-bigPattern-3 {
    bottom: 400px;
  }
  .aboutUs-bigPattern-2 {
    width: 70vw;
  }
  .faq_qesutions {
    width: 100%;
    padding: 0 15px;
  }
  .faq_collapse {
    width: 100%;
    font-size: 17px;
  }
  .faq_question h1 {
    font-size: clamp(1.125rem, 0rem + 5.625vw, 2.109375rem);
  }
  .faq_q,
  .faq_collapse .ant-collapse-header-text {
    font-size: 17px !important;
  }
  .faq_a {
    font-size: 17px !important;
  }
}
