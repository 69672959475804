.card_slider {
  position: relative;
  width: 23rem;
  height: 23rem;
  perspective: 500px;
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ecosystem_slider {
  height: 40rem;
  width: 100%;
  align-items: flex-start;
}
.news_slider {
  width: 33rem;
}
.ecosystem_slider .btn {
  font-size: 10px !important;
}
.card-container {
  position: absolute;
  /* filter: blur(calc(var(--abs-offset) * 1rem)); */
  /* -webkit-filter: blur(calc(var(--abs-offset) * 1rem)); */
  transform: 
  /* rotateY(calc(var(--offset) * 50deg)) */
    scaleY(calc(1 + var(--abs-offset) * -0.4))
    translateZ(calc(var(--abs-offset) * -30rem))
    translateX(calc(var(--direction) * -5rem));
  opacity: var(--opacity);
  /* -webkit-transform: -webkit-rotateY(calc(var(--offset) * 50deg)) -webkit-scaleY(
      calc(1 + var(--abs-offset) * -0.4)
    ) -webkit-translateZ(calc(var(--abs-offset) * -30rem)) -webkit-translateX(calc(var(
            --direction
          ) * -5rem)); */
  /* -webkit-opacity: var(--opacity); */
  transition: all 0.3s ease-out;
  /* -webkit-transition: all 0.3s ease-out; */
}
.nav {
  position: absolute;
  top: calc(50% - 1.5rem);
  z-index: 2;
  width: 45px;
  height: 45px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
}
.nav img {
  width: 40%;
  height: 40%;
}
.nav.left {
  left: 1rem;
}
.nav.right {
  right: 1rem;
  transform: rotate(180deg);
}
