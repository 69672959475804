.contactPage_address_wrapper {
  display: flex;
  justify-content: space-between;
  width: 63vw;
}
.contactPage_address_1 {
  /* width: 50%; */
  height: 100%;
  /* background: green; */
}
.contactPage_address_2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 400px;
  padding: 28px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.07);
}
.contactPage_address {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
  color: #313a4b;
  font-size: 20px;
  font-weight: 500;
}
.contactPage_medias {
  display: flex;
  align-items: center;
  gap: 20px;
}
.contactPage_medias a {
  width: 20px;
  height: 20px;
}
.contactPage_medias a img {
  width: 100%;
  height: 100%;
}
.contactPage_address_title {
  color: #151c26;
  font-weight: 600;
  font-size: 20px;
}
@media screen and (max-width: 1600px) {
  .contactPage_address,
  .contactPage_address_title {
    font-size: 18px;
  }
  .contactPage_address_2 {
    width: 65%;
  }
}
@media screen and (max-width: 1280px) {
  .contactPage_address,
  .contactPage_address_title {
    font-size: 16px;
  }
  .contactPage_address_2 {
    width: 60%;
    height: 350px;
  }
}
@media screen and (max-width: 1100px) {
  .contactPage_address,
  .contactPage_address_title {
    font-size: 14px;
  }
  .contactPage_address_2 {
    width: 50%;
    height: 300px;
  }
  .contactPage_medias a {
    width: 15px;
    height: 15px;
  }
}
@media screen and (max-width: 800px) {
  .contactPage_address_wrapper {
    flex-direction: column;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .contactPage_address_2 {
    width: 100%;
    margin-top: 20px;
  }
  .contactPageContactForm {
    width: 100vw !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
