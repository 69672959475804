.bgLogo {
  width: 32vw;
  position: relative;
}
.bgLogo img {
  width: 100%;
  height: 100%;
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 975px) {
  .mainBigPattern {
    position: absolute;
  }
  .logoMobile {
    padding-left: 20px;
    position: relative;
    z-index: 3;
  }
  .bgLogo {
    width: 100vw;
  }
}
