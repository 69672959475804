.ecosystemDetails {
  width: 63vw;
  display: flex;
  flex-direction: column;
  gap: 90px;
  color: var(--text-color);
}
.ecosystemDetails_ {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.ecosystemDetails-title {
  display: flex;
  align-items: center;
  gap: 20px;
}
.ecosystemDetails-title img {
  /* width: 30px; */
  height: 40px;
  aspect-ratio: 1 / 1;
}
.ecosystemDetails-content {
  font-weight: 500;
  text-align: justify;
}
.ecosystemDetails-content,
.memberContent {
  font-size: clamp(1.25rem, 0rem + 1.0417vw, 3.75rem);
}
.ecoSystemDetailsMember_wrapper {
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.ecosystemDetails-header {
  width: 100%;
}
@media screen and (max-width: 1920px) {
  .ecosystemDetails-content,
  .memberContent {
    font-size: clamp(1.041875rem, 0.0013rem + 1.0406vw, 1.25rem);
  }
}
@media screen and (max-width: 1600px) {
  .ecosystemDetails-content,
  .memberContent {
    font-size: clamp(0.91125rem, -0.0031rem + 1.045vw, 1.041875rem);
  }
}
@media screen and (max-width: 1400px) {
  .ecosystemDetails-content,
  .memberContent {
    font-size: clamp(0.833125rem, -0.0002rem + 1.0417vw, 0.91125rem);
  }
}
@media screen and (max-width: 1280px) {
  .ecosystemDetails-content,
  .memberContent {
    font-size: clamp(0.71625rem, 0.002rem + 1.0389vw, 0.833125rem);
  }
}
@media screen and (max-width: 1100px) {
  .ecosystemDetails-content,
  .memberContent {
    font-size: clamp(0.520625rem, -0.001rem + 1.0433vw, 0.71625rem);
  }
}
/* @media screen and (max-width: 800px) {
  .ecosystemDetails-content,
  .memberContent {
    font-size: clamp(0.390625rem, 0.0006rem + 1.04vw, 0.520625rem);
  }
} */
@media screen and (max-width: 600px) {
  .ecosystemDetails {
    width: 90vw;
  }
  .ecosystemDetails-content,
  .memberContent {
    font-size: 14px;
  }
}
