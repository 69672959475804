.fullProjectContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fullProjectBg {
  height: 348px;
  position: relative;
  margin-top: 25px;
}
.backBtn {
  border-radius: 50%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #fff;
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.backBtn img {
  width: 20px;
  height: 20px;
}
.fullProjectBg .bgImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fullProjectBg_title {
  width: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(2.5rem, 0rem + 2.0833vw, 7.5rem);
  font-weight: 900;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  background: #dd264e7a;
  padding-left: 9px ;
  padding-right: 9px;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
}
.fullProject_titleInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fullProject_problemInner,
.fullProject_titleInner,
.fullProject_inner,
.roadMap_heading {
  width: 100%;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.07);
  padding: 40px 30px;
}
.fullProject_inner {
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f9fbff;
}
.fullProject_titleInner .ourProject__author {
  margin: 0;
  gap: 10px;
}
.fullProject_titleInner .ourProject__author img {
  width: 48px;
  height: 48px;
}
.titlePart {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 40vw;
  margin-right: 60px;
}
.problem_heading,
.titlePart {
  font-size: clamp(1.5rem, 0rem + 1.25vw, 4.5rem);
}
.titlePart .flag {
  border-radius: 46px;
  border: 1px solid rgba(49, 58, 75, 0.16);
  background: rgba(244, 244, 244, 0.47);
  padding: 5px 15px;
}
.problem_header,
.problem_heading,
.download_pdf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.download_pdf {
  color: #1bb7d9;
  font-family: Montserrat;
  font-weight: 500;
  padding: 0;
}
.problem_inner,
.download_pdf,
.titlePart .flag {
  font-size: clamp(1.25rem, 0rem + 1.0417vw, 3.75rem);
}
.problem_inner {
  color: var(--text-color);
  text-align: justify;
  font-weight: 500;
}
.roadMap_heading {
  justify-content: flex-start;
}
.roadMapContainer .roadMap_heading,
.workTeamContainer .roadMap_heading {
  border: 1px solid rgba(49, 58, 75, 0.27);
}
._inner {
  padding: 40px 30px;
  overflow: hidden;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.07);
}
.month_circle {
  width: 12px;
  height: 12px;
  border: 1px solid var(--text-color);
  border-radius: 50%;
  z-index: 1;
}
.chart,
.chart_month {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chart_info {
  height: 110px;
  overflow-y: auto;
}
.chart_info::-webkit-scrollbar {
  display: none;
}
.chart_info h1 {
  font-size: clamp(1.2375rem, 0.0003rem + 1.031vw, 3.711875rem);
  color: #474a54;
}
.chart_info p {
  font-size: 14px;
  color: #6e7175;
  margin: 0;
}
.chart_month {
  gap: 7px;
  margin-top: 20px;
  font-size: 16px;
}
.chart_line {
  width: 100%;
  position: absolute;
  height: 1px;
  bottom: 31px;
  left: 0;
  background: var(--text-color);
}
.budgetContainer {
  display: flex;
  justify-content: center;
  gap: 25px;
}
.budget,
.collected
/* , .remaining */ {
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.09);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 180px;
  color: #4c4c4c;
}
.budget img,
.collected img {
  width: 80px;
  padding-top: 20px;
}
.budget .budgetPrice h2,
.collectedPrice h2 {
  color: var(--main-color);
}
.dontaionBtns {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: fit-content !important;
  margin: 0 auto;
  transition: 0.3s ease;
}
.fullProject_slider,
.fullProjectInner {
  width: 100%;
}
.fullProjectInner {
  padding: 0 15.1563%;
}
.download_pdf img,
.problem_heading img {
  width: clamp(2.419375rem, 0rem + 2.0161vw, 7.258125rem);
}
.flag img {
  width: clamp(1.476875rem, 0rem + 1.2307vw, 4.430625rem);
}
.chartContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  position: relative;
  margin-bottom: 50px;
}
.firstTeam,
.secondTeam {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(9, 28, 57, 0.06);
  padding: 25px;
}
.member {
  position: relative;
  width: 14vw;
  height: 14vw;
}
.member .teamMember_bgImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: inherit;
  z-index: 1;
}
.teamMember_img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  height: 75%;
  border-radius: 50%;
}
.member_name {
  text-align: center;
  color: var(--text-color);
  font-weight: 500;
  font-size: clamp(1.25rem, 0rem + 1.0417vw, 3.75rem);
}
.memberWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 19vw;
}
.memberWrapper_withoutName {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  /* width: 300px;
  height: 300px; */
}
a.fx-Revolve:focus {
  box-shadow: 0 0 3pt 2pt #75acf8;
  position: relative;
  outline: 0;
}
a.fx-Revolve:focus:before {
  border-radius: inherit;
  content: "";
  width: calc(100% - 2.2em);
  height: calc(100% - 2.2em);
  margin: 1em;
  box-shadow: inset 0 0 3pt 2pt #75acf8;
  position: absolute;
  left: 0;
  top: 0;
}
.teamMembers {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.partners._inner {
  box-shadow: none;
  margin-top: 0 !important;
}
.partnersContainer {
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.07);
}
.titlePart,
.problem_heading,
.ourProject__author {
  font-size: clamp(1.5rem, 0rem + 1.25vw, 4.5rem);
}
.dontaionBtnsFixed {
  z-index: 999;
}
.dontaionBtnsFixed .btn:last-child {
  flex-direction: row;
  position: fixed;
  left: 50%;
  bottom: 15px;
  transform: translateX(-50%);
}
.dontaionBtns .btn {
  font-size: clamp(1rem, 0rem + 0.8333vw, 3rem);
}
.ourProject__projectInfo {
  width: 100%;
}
@media screen and (max-width: 1920px) {
  .titlePart,
  .problem_heading,
  .ourProject__author {
    font-size: clamp(1.25rem, 0rem + 1.25vw, 1.5rem);
  }
  .download_pdf,
  .problem_inner,
  .titlePart .flag {
    font-size: clamp(1.041875rem, 0.0013rem + 1.0406vw, 1.25rem);
  }
  .download_pdf img,
  .problem_heading img {
    width: clamp(2.01625rem, 0.0006rem + 2.0156vw, 2.419375rem);
  }
  .fullProjectBg_title {
    font-size: clamp(2.083125rem, -0.0013rem + 2.0844vw, 2.5rem);
  }
  .flag img {
    width: clamp(1.230625rem, -0.0006rem + 1.2312vw, 1.476875rem);
  }
  .chart_info h1 {
    font-size: clamp(1.03125rem, 0rem + 1.0313vw, 1.2375rem);
  }
  /* .chart_info p {
    font-size: clamp(0.390625rem, 0rem + 0.3906vw, 0.46875rem);
  } */
  .member_name {
    font-size: clamp(1.041875rem, 0.0013rem + 1.0406vw, 1.25rem);
  }
  .dontaionBtns .btn {
    font-size: clamp(0.833125rem, -0.0013rem + 0.8344vw, 1rem);
  }
}
@media screen and (max-width: 1600px) {
  .titlePart,
  .problem_heading,
  .ourProject__author {
    font-size: clamp(1.09375rem, 0rem + 1.25vw, 1.25rem);
  }
  .download_pdf,
  .problem_inner,
  .titlePart .flag {
    font-size: clamp(0.91125rem, -0.0031rem + 1.045vw, 1.041875rem);
  }
  .download_pdf img,
  .problem_heading img {
    width: clamp(1.764375rem, 0.0013rem + 2.015vw, 2.01625rem);
  }
  .fullProjectBg_title {
    font-size: clamp(1.823125rem, 0.0031rem + 2.08vw, 2.083125rem);
  }
  .flag img {
    width: clamp(1.076875rem, 0.0006rem + 1.23vw, 1.230625rem);
  }
  .chart_info h1 {
    font-size: clamp(0.9025rem, 0.0012rem + 1.03vw, 1.03125rem);
  }
  /* .chart_info p {
    font-size: clamp(0.341875rem, 0.0006rem + 0.39vw, 0.390625rem);
  } */
  .member_name {
    font-size: clamp(0.91125rem, -0.0031rem + 1.045vw, 1.041875rem);
  }
  .dontaionBtns .btn {
    font-size: clamp(0.729375rem, 0.0031rem + 0.83vw, 0.833125rem);
  }
  .member {
    /* width: 230px; */
    /* height: 230px; */
  }
}
@media screen and (max-width: 1560px) {
  .memberWrapper {
    /* width: 250px; */
  }
  .fullProjectInner {
    padding: 0 13%;
  }
}
@media screen and (max-width: 1400px) {
  .titlePart,
  .problem_heading,
  .ourProject__author {
    font-size: clamp(1rem, 0rem + 1.25vw, 1.09375rem);
  }
  .download_pdf,
  .problem_inner,
  .titlePart .flag {
    font-size: clamp(0.833125rem, -0.0002rem + 1.0417vw, 0.91125rem);
  }
  .download_pdf img,
  .problem_heading img {
    width: clamp(1.613125rem, -0.0002rem + 2.0167vw, 1.764375rem);
  }
  .fullProjectBg_title {
    font-size: clamp(1.666875rem, 0.0002rem + 2.0833vw, 1.823125rem);
  }
  .flag img {
    width: clamp(0.984375rem, -0.0023rem + 1.2333vw, 1.076875rem);
  }
  .chart_info h1 {
    font-size: clamp(0.825rem, -0.0017rem + 1.0333vw, 0.9025rem);
  }
  /* .chart_info p {
    font-size: clamp(0.3125rem, -0.0008rem + 0.3917vw, 0.341875rem);
  } */
  .member_name {
    font-size: clamp(0.833125rem, -0.0002rem + 1.0417vw, 0.91125rem);
  }
  .dontaionBtns .btn {
    font-size: clamp(0.666875rem, 0.0002rem + 0.8333vw, 0.729375rem);
  }
  .member {
    width: 170px;
    height: 170px;
  }
}
@media screen and (max-width: 1340px) {
  .memberWrapper {
    width: 200px;
  }
}
@media screen and (max-width: 1280px) {
  .titlePart,
  .problem_heading,
  .ourProject__author {
    font-size: clamp(0.859375rem, 0rem + 1.25vw, 1rem);
  }
  .download_pdf,
  .problem_inner,
  .titlePart .flag {
    font-size: clamp(0.71625rem, 0.002rem + 1.0389vw, 0.833125rem);
  }
  .download_pdf img,
  .problem_heading img {
    width: clamp(1.38625rem, -0.0002rem + 2.0167vw, 1.613125rem);
  }
  .fullProjectBg_title {
    font-size: clamp(1.4325rem, 0.0002rem + 2.0833vw, 1.666875rem);
  }
  .flag img {
    width: clamp(0.84625rem, 0.0022rem + 1.2278vw, 0.984375rem);
  }
  .chart_info h1 {
    font-size: clamp(0.70875rem, -0.0017rem + 1.0333vw, 0.825rem);
  }
  /* .chart_info p {
    font-size: clamp(0.26875rem, 0.0014rem + 0.3889vw, 0.3125rem);
  } */
  .member_name {
    font-size: clamp(0.71625rem, 0.002rem + 1.0389vw, 0.833125rem);
  }
  .arrowBtn_teamMember img {
    width: 20px;
    height: 20px;
  }
  .fullProject_titleInner .ourProject__author img {
    width: 43px;
    height: 43px;
  }
  .dontaionBtns .btn {
    font-size: clamp(0.573125rem, 0.0002rem + 0.8333vw, 0.666875rem);
  }
}
@media screen and (max-width: 1130px) {
  .memberWrapper {
    width: 170px;
  }
}
@media screen and (max-width: 1100px) {
  .titlePart,
  .problem_heading,
  .ourProject__author {
    font-size: clamp(0.625rem, 0rem + 1.25vw, 0.859375rem);
  }
  .download_pdf,
  .problem_inner,
  .titlePart .flag {
    font-size: clamp(0.520625rem, -0.001rem + 1.0433vw, 0.71625rem);
  }
  .download_pdf img,
  .problem_heading img {
    width: clamp(1.008125rem, -0.0002rem + 2.0167vw, 1.38625rem);
  }
  .fullProjectInner {
    padding: 0 10%;
  }
  .fullProjectBg_title {
    font-size: clamp(1.041875rem, 0.0002rem + 2.0833vw, 1.4325rem);
  }
  .flag img {
    width: clamp(0.615625rem, 0.0006rem + 1.23vw, 0.84625rem);
  }
  .chart_info h1 {
    font-size: clamp(0.515625rem, 0.0006rem + 1.03vw, 0.70875rem);
  }
  /* .chart_info p {
    font-size: clamp(0.195625rem, 0.0006rem + 0.39vw, 0.26875rem);
  } */
  .dontaionBtns .btn {
    font-size: clamp(0.416875rem, 0.0002rem + 0.8333vw, 0.573125rem);
  }
  .dontaionBtnsFixed .btn:last-child {
    width: 40vw !important;
  }
  .fullProject_titleInner .ourProject__author img {
    width: 38px;
    height: 38px;
  }
  .member {
    width: 150px;
    height: 150px;
  }
  .memberWrapper_withoutName {
    gap: 6px;
  }
}
@media screen and (max-width: 900px) {
  .memberWrapper {
    width: 150px;
  }
}
@media screen and (max-width: 800px) {
  .member {
    width: 100%;
    height: 100%;
  }
  .titlePart,
  .problem_heading,
  .ourProject__author {
    font-size: clamp(0.46875rem, 0rem + 1.25vw, 0.625rem);
  }
  .download_pdf,
  .problem_inner,
  .titlePart .flag {
    font-size: clamp(0.390625rem, 0.0006rem + 1.04vw, 0.520625rem);
  }
  .download_pdf img,
  .problem_heading img {
    width: clamp(0.75625rem, 0.0006rem + 2.015vw, 1.008125rem);
  }
  .budgetContainer {
    flex-wrap: wrap;
  }
  .fullProjectBg_title {
    font-size: clamp(0.78125rem, -0.0006rem + 2.085vw, 1.041875rem);
  }
  .flag img {
    width: clamp(0.46125rem, -0.0019rem + 1.235vw, 0.615625rem);
  }
  .chart_info h1 {
    font-size: clamp(0.386875rem, 0.0006rem + 1.03vw, 0.515625rem);
  }
  /* .chart_info p {
    font-size: clamp(0.14625rem, -0.0019rem + 0.395vw, 0.195625rem);
  } */
  .firstTeam,
  .secondTeam {
    flex-direction: column;
    gap: 30px;
  }
  .memberWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 0px 16px 0px rgba(9, 28, 57, 0.06);
    padding: 30px 0 20px 0;
  }
  .firstTeam,
  .secondTeam {
    box-shadow: none;
    padding: 0;
    background-color: transparent;
  }
  .arrowBtn_teamMember img {
    width: 35px;
    height: 35px;
  }
  .dontaionBtns .btn {
    font-size: clamp(1.640625rem, 0rem + 4.375vw, 2.1875rem);
  }
  .member_name {
    font-size: clamp(1rem, 0rem + 5vw, 1.875rem);
  }
  .dontaionBtnsFixed .btn:last-child {
    width: 50vw !important;
  }
  /* .memberWrapper_withoutName {
    width: 192px;
    height: 192px;
  } */
}
@media screen and (max-width: 600px) {
  .fullProjectInner {
    padding: 0;
  }
  .fullProject_inner {
    padding: 30px 0;
  }
  .fullProject_titleInner {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .titlePart,
  .titlePart .flag,
  .problem_heading,
  .ourProject__author {
    font-size: clamp(0.75rem, 0rem + 3.75vw, 1.40625rem);
  }
  .flag img {
    padding-top: 4px;
    width: 15px;
  }
  .problem_heading img {
    width: 30px;
  }
  .download_pdf img {
    width: 20px;
  }
  .download_pdf {
    width: 100%;
  }
  .download_pdf,
  .problem_inner,
  .titlePart .flag,
  .fullProjectBg_title {
    font-size: 14px;
  }
  .titlePart {
    width: 100%;
    justify-content: space-between;
  }
  .innerPartners {
    padding: 0;
    gap: 15px;
  }
  .innerPartners .partner {
    width: 100%;
  }
  .chart_info h1 {
    font-weight: 400;
    font-size: clamp(0.375rem, 0rem + 1.875vw, 0.703125rem);
  }
  /* .chart_info p {
    font-size: clamp(0.1875rem, -0.0004rem + 0.9393vw, 0.351875rem);
  } */
  .month_circle {
    width: 6px;
    height: 6px;
  }
  .month {
    font-size: 10px;
  }
  .chart_line {
    bottom: 22px;
  }
  .backBtn img {
    width: 16px;
    height: 16px;
  }
  .fullProjectBg {
    height: 250px;
  }
  .dontaionBtns .btn {
    font-size: clamp(0.875rem, 0rem + 4.375vw, 1.640625rem);
    padding: 10px 30px !important;
  }
}

.images-container {
  display: flex;
  /* max-width: 500px; */
  gap: 30px;
  overflow: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  transition: scroll 0.3s ease-in-out;
}
.images-container::-webkit-scrollbar {
  display: none;
}
.nav-btn {
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background-color: #c3c3c3;
  border-radius: 6px;
  height: 200px;
  width: 30px;
  margin: 5px;
}
.projectDetails_slider_1 {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}
.projectDetails_slider_1 .leftBtn {
  left: 10px;
}
.projectDetails_slider_1 .rightBtn {
  right: 10px;
}
