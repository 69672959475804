.news {
  width: 500px;
  flex: 0 0 auto;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: -15px 6px 28px rgba(34, 43, 60, 0.12);
}
.newsImg {
  width: 100%;
  height: 350px;
  border-radius: inherit;
}
.newsImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.newsContainer {
  /* padding-bottom: 0; */
  /* padding-left: 350px; */
  /* padding-right: 300px; */
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* gap: 40px; */
}
.newsWrapper {
  /*width: 63vw;*/
}
.newsContent {
  padding: 20px;
}
.newsContent h1 {
  font-size: clamp(1.25rem, 0rem + 1.0417vw, 3.75rem);
  font-weight: 600;
  margin: 1rem 0;
}
.newsContent p {
  color: #313a4b;
  font-size: clamp(1rem, 0rem + 0.8333vw, 3rem);
  font-style: normal;
  font-weight: 500;
}
.newsBtn {
  display: none;
}
@media screen and (max-width: 1920px) {
  .newsContent h1 {
    font-size: clamp(1.041875rem, 0.0013rem + 1.0406vw, 1.25rem);
  }
  .newsContent p {
    font-size: clamp(0.833125rem, -0.0013rem + 0.8344vw, 1rem);
  }
}
@media screen and (max-width: 1600px) {
  .newsContent h1 {
    font-size: clamp(0.91125rem, -0.0031rem + 1.045vw, 1.041875rem);
  }
  .newsContent p {
    font-size: clamp(0.729375rem, 0.0031rem + 0.83vw, 0.833125rem);
  }
}
@media screen and (max-width: 1400px) {
  .newsContent h1 {
    font-size: clamp(0.833125rem, -0.0002rem + 1.0417vw, 0.91125rem);
  }
  .newsContent p {
    font-size: clamp(0.666875rem, 0.0002rem + 0.8333vw, 0.729375rem);
  }
}
@media screen and (max-width: 1350px) {
  .newsContainer {
    gap: 18px;
    padding-left: 320px;
    padding-right: 300px;
  }
}
@media screen and (max-width: 1280px) {
  .newsContent h1 {
    font-size: clamp(0.71625rem, 0.002rem + 1.0389vw, 0.833125rem);
  }
  .newsContent p {
    font-size: clamp(0.573125rem, 0.0002rem + 0.8333vw, 0.666875rem);
  }
}
@media screen and (max-width: 1100px) {
  .newsContainer {
    flex-direction: column;
  }
  .news {
    width: 35vw;
  }
  .newsContent h1 {
    font-size: 20px;
  }
  .newsContent p {
    font-size: 16px;
  }
}
@media screen and (max-width: 975px) {
  .newsContainer {
    flex-direction: column;
    position: relative;
    padding: 0;
    /* padding-bottom: 50px; */
  }
  .newsContent {
    padding: 10px;
  }
  .newsContent h1 {
    font-size: 12px;
  }
  .newsContent p {
    font-size: 10px;
  }
  .newsProjectDetailsSlider {
    padding: 0;
    width: 100%;
  }
  .newsProjectDetailsSlider .images-container {
    align-items: center;
  }
  .newsWrapper {
    width: 100%;
  }
  .news {
    /* width: 50vw;
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    scale: 0.85; */
    width: 65vw;
    height: 300px;
    overflow: hidden;
    transition: 0.3s ease;
  }
  /* .news:first-child,
  .activeNews1 {
    transform: translate(-110%, -58%);
  }
  .news:nth-child(2) {
    z-index: 3;
  } */
  /* .news:last-child,
  .activeNews2 {
    transform: translate(-5%, -58%);
  } */
  /* .newsBtn {
    width: 60px;
    height: 60px;
    display: block;
    z-index: 9999;
    margin-top: 20px;
  } */
  /* .newsBtn img {
    width: 100%;
    height: 100%;
  }
  .newsBtn.leftBtn {
    left: 10px;
  }
  .newsBtn.rightBtn {
    right: 10px;
  } */
  .newsImg {
    height: 145px;
  }
  .activeNews {
    scale: 1;
    z-index: 4;
    transform: translate(-50%, -50%) !important;
  }
}
