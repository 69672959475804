:root {
  --main-color: #dd264e;
  --text-color: #313a4b;
  --text-color-2: #9b9b9b;
  --mention-color: #1877f2;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  /* scroll-behavior: smooth; */
  font-family: Montserrat, Montserrat Arm, sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
  color: #000;
}

button {
  background-color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
}
button:disabled {
  opacity: 0.99;
  cursor: not-allowed;
}
.pagination button:disabled {
  border: 1px solid #ddd !important;
}
button:disabled img {
  opacity: 0.2;
}
h1,
h2 {
  margin: 0;
}
.separatedPart {
  height: 50px;
  width: 100%;
  background-color: rgba(21, 28, 38, 0.03);
}
.btns {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.passwordInputField {
  position: relative;
}
.passwordInputField button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}
.passwordInputField button img {
  width: 20px;
  height: 20px;
}
.passwordInputField input {
  width: 100%;
  padding-right: 50px;
}
/* .ant-dropdown-menu {
  top: -30px;
  padding-top: 40px !important;
  padding-bottom: 20px !important;
  border-bottom-left-radius: 45px !important;
  border-bottom-right-radius: 45px !important;
}
.ant-dropdown-open {
  z-index: 9999;
}
.calendar_dropdown .ant-dropdown-menu {
  top: inherit !important;
  padding-top: inherit !important;
  padding-bottom: inherit !important;
} */

.loadingContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
body.no-scroll {
  overflow: hidden;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.mentioned_link {
  color: var(--main-color);
  font-weight: 700;
}
.heart {
  width: 20px;
  fill: gray;
  transition: fill 0.5s, transform 0.5s;
  pointer-events: none;
}
.heart.liked {
  fill: #ee3529;
}
.heartWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.heartWrapper .ant-spin,
.donationBtn .ant-spin {
  width: 20px !important;
  height: 17.8px !important;
  margin: 0;
  align-items: center;
  justify-content: center;
  display: flex;
}
.personalInfo_wrapper {
  opacity: 0;
  animation: reveal 0.5s ease forwards;
}
.ant-popover-content:has(.support_popover) .ant-popconfirm-buttons {
  visibility: hidden;
}
.mentioned_txt {
  color: var(--mention-color) !important;
  font-weight: 600;
  cursor: pointer;
}
.signIn_another_privacy {
  color: var(--text-color-2);
  text-align: center;
  font-weight: 500;
  line-height: 132.143%;
}
.signIn_another_privacy p,
.signIn_another_privacy .mentioned_txt {
  font-size: 14px !important;
}
@media screen and (max-width: 975px) {
  .btns {
    flex-direction: column;
  }
  .btns,
  .btn:not(.lang, .signIn-btn) {
    width: 100% !important;
  }
  .container {
    min-height: auto;
  }
  .headerContainer,
  .innerPartners,
  .newsBtns,
  .footerContainer,
  .slider .innerCarousel,
  .aboutContainer,
  .navbarContainer,
  .pagination,
  .aboutUs_privacy {
    padding-left: 15px !important;
    padding-right: 15px !important;
    /* padding-left: clamp(1.5625rem, -0.0004rem + 7.8143vw, 2.93rem) !important; */
    /* padding-right: clamp(1.5625rem, -0.0004rem + 7.8143vw, 2.93rem) !important; */
  }
  .differedHeaderContainer {
    padding-left: 15px !important;
    padding-right: 50px !important;
  }
}
