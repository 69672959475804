.ecosystemContainer {
  padding-bottom: 0;
  width: 63vw;
}
.ecosystem {
  margin-bottom: 150px;
}
.ecosystem:last-child {
  margin-bottom: 50px;
}
.ecosystemInner {
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: center;
}
.ecosystemContainer .innerCarousel {
  justify-content: center;
}
.ecosystem:nth-child(even) .ecosystemInner {
  flex-direction: row-reverse;
}
.partners {
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 50px;
}
.ecosystemContainer .partners {
  /* justify-content: center; */
}
.ecosystemContainer .partners .partner {
  width: 12vw;
}
.partner img {
  width: 100%;
}
.ecosystemInner .headerContainer {
  width: 39vw;
}
.ecosystemInner .img {
  width: 20vw;
}
.img img {
  width: 100%;
  height: 100%;
}
.innerPartners {
  display: flex;
  gap: 15px;
  cursor: grab;
}
.ecosystemDetails_partners {
  flex-wrap: nowrap !important;
  width: 100% !important;
  overflow-x: auto;
  overflow-y: hidden;
}
.ecosystemDetails_partners::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 975px) {
  .ecosystemContainer {
    padding: 0;
    width: 100%;
  }
  .ecosystemInner .img {
    display: none;
  }
  .partners {
    flex-wrap: nowrap;
    gap: 20px;
  }
  .partner {
    width: 28vw;
    height: 80px;
  }
  .ecosystemInner .inner {
    width: 100%;
  }
  .innerPartners {
    justify-content: space-between;
  }
  .ecosystem {
    /* margin: 60px 0; */
  }
  .ecosystemContainer .partners .partner {
    width: 26vw;
  }
}
