.headerContainer {
  display: flex;
  gap: 20px;
  width: 63vw;
  z-index: 2;
  position: relative;
}
.header {
  color: #151c26;
  font-weight: 700;
}
.header h1 {
  font-size: clamp(2.25rem, 0rem + 1.875vw, 6.75rem);
}
.header_ecosystem {
  display: flex;
  align-items: center;
  gap: 20px;
}
.ecosystemImg {
  width: 30px;
  height: 30px;
}
.ecosystemImg img {
  width: 100%;
  height: 100%;
}
.header h2 {
  font-size: clamp(1.25rem, 0rem + 1.0417vw, 3.75rem);
  margin-bottom: 2.5rem !important;
}
.inner {
  font-weight: 500;
  font-size: clamp(1.125rem, 0rem + 0.9375vw, 3.375rem);
  text-align: justify;
  line-height: 155%;
  color: var(--text-color);
}
.inner a {
  color: var(--main-color);
}
.mainImgHeader {
  display: flex;
  justify-content: center;
}
.importantInner {
  line-height: 155%;
  margin-top: 50px;
}
.importantInner p {
  margin: 30px 0;
}
.hypothesesInner {
  text-align: initial;
}
@media screen and (max-width: 1920px) {
  .header h1 {
    font-size: clamp(1.875rem, 0rem + 1.875vw, 2.25rem);
  }
  .header h2 {
    font-size: clamp(1.041875rem, 0.0013rem + 1.0406vw, 1.25rem);
  }
  .inner {
    font-size: clamp(0.9375rem, 0rem + 0.9375vw, 1.125rem);
  }
}
@media screen and (max-width: 1600px) {
  .header h1 {
    font-size: clamp(1.640625rem, 0rem + 1.875vw, 1.875rem);
  }
  .header h2 {
    font-size: clamp(0.91125rem, -0.0031rem + 1.045vw, 1.041875rem);
  }
  .inner {
    font-size: clamp(0.820625rem, 0.0025rem + 0.935vw, 0.9375rem);
  }
}
@media screen and (max-width: 1400px) {
  .header h1 {
    font-size: clamp(1.5rem, 0rem + 1.875vw, 1.640625rem);
  }
  .header h2 {
    font-size: clamp(0.833125rem, -0.0002rem + 1.0417vw, 0.91125rem);
  }
  .inner {
    font-size: clamp(0.75rem, -0.0033rem + 0.9417vw, 0.820625rem);
  }
}
@media screen and (max-width: 1280px) {
  .header h1 {
    font-size: clamp(1.289375rem, 0.0022rem + 1.8722vw, 1.5rem);
  }
  .header h2 {
    font-size: clamp(0.71625rem, 0.002rem + 1.0389vw, 0.833125rem);
  }
  .inner {
    font-size: clamp(0.644375rem, -0.0011rem + 0.9389vw, 0.75rem);
  }
}
@media screen and (max-width: 1100px) {
  .header h1 {
    font-size: clamp(0.9375rem, -0.0008rem + 1.8767vw, 1.289375rem);
  }
  .header h2 {
    font-size: clamp(0.520625rem, -0.001rem + 1.0433vw, 0.71625rem);
  }
  .inner {
    font-size: clamp(0.46875rem, 0.0004rem + 0.9367vw, 0.644375rem);
  }
}
@media screen and (max-width: 975px) {
  .headerContainer {
    width: 100% !important;
    gap: 15px;
  }
  .iconTitle {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .iconTitleParagraph {
    align-items: flex-start;
  }
  .ecosystemContainer .iconTitle {
    margin-bottom: 30px;
  }
  .headerIconWrapper {
    width: 30px;
    height: 30px;
  }
  .headerIconWrapper img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .inner {
    font-size: 17px;
  }
  .header h2 {
    font-size: 16px;
  }
  .iconTitleStart {
    align-items: flex-start;
  }
  .headerContent {
    margin: auto 0;
  }
  .mainPageHeader .header h1 {
    font-size: clamp(1.625rem, 0rem + 8.125vw, 3.046875rem);
    color: #151c26;
  }
  .header_ecosystem {
    /* margin-bottom: 50px; */
  }
}
@media screen and (max-width: 600px) {
  .ecosystem {
    width: 80vw;
    /* height: 800px; */
    /* margin-top: 650px; */
    margin: 0 !important;
    background: #fff;
  }
  .header h1 {
    font-size: clamp(1.125rem, 0rem + 5.625vw, 2.109375rem);
  }
  .mainImgHeader {
    width: 200px;
    height: 200px;
    margin: auto;
  }
  .mainImgHeader img {
    height: 100%;
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
}
